@import '../../../../scss/theme-bootstrap';

.cart-item {
  @include pie-clearfix;
  display: block;
  text-align: left;
  color: $color-black;
  font-size: 15px;
  line-height: 1.2;
  padding: 18px 0;
  border-bottom: solid 1px $color-light-gray;
  width: 100%;
  &:hover {
    color: $color-black;
    text-decoration: none;
  }
  // Layout:
  &__img,
  &__info,
  &__cart-details {
    float: left;
    display: block;
  }
  &__img {
    width: 16.5%;
    margin-right: 4.25%;
  }
  &__info {
    width: 52.75%;
    margin-right: 4.25%;
    &__header,
    &-header {
      min-height: 50px;
    }
  }
  &__cart-details {
    width: 22.25%;
    text-align: right;
  }
  .pre-order-msg {
    &__cart-overlay {
      padding-top: 10px;
      font-family: $font--futura-book;
      font-size: 14px;
    }
  }
  // Styles:
  &__subheading {
    @include font--heading;
    font-size: 9px;
    margin: 0 0 6px;
    @if $cr18 == true {
      color: $color-cr18-black;
    }
    @if $cr18 == false {
      color: $color-green;
    }
  }
  &__title {
    @include font--subheading;
    font-size: 16px;
    margin: 0 0 3px;
    line-height: 0.9;
  }
  &__subline {
    font-size: 16px;
    font-family: $font--text;
  }
  &__sku-label,
  &__qty {
    @include font--cta;
    text-transform: uppercase;
    font-size: 14px;
  }
  &__qty {
    margin: 0 0 15px;
  }
  &__price {
    @include font--text-alt;
    font-size: 18px;
    letter-spacing: 0;
    color: $color-black;
    // Intentionally changed to match "Your Bag Contains" header size (ORNF-122)
    // font-size: 21px;
  }
  &__shade {
    @include pie-clearfix;
    margin: 10px 0 0;
    &__swatch {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      margin-right: 10px;
      float: left;
    }
    &__name {
      @include font--subheading;
      @include font-padding(subheading, 14px, 18px);
      font-size: 14px;
      display: inline-block;
      margin: 0;
      float: left;
    }
  }
}

.cart-item.cart-item-kit {
  position: relative;
  .cart-price__section {
    display: inline-block;
    position: absolute;
    top: 22%;
    .cart-item__quantity {
      margin-bottom: 10px;
    }
  }
  .cart-item__info {
    .cart-item__subheading {
      font-family: $font--futura-medium;
      font-size: 12px;
      letter-spacing: 1px;
    }
  }
  .cart-item__cart-details {
    display: none;
  }
  .cart-item__kit-items {
    width: 70%;
    margin-left: 83px;
    .cart-item {
      .cart-item__img {
        display: none;
      }
      .cart-item__info {
        width: 71%;
        padding-top: 10px;
        border-top: 1px solid $color-light-gray;
      }
    }
  }
}
